import React from 'react';
import { classes, st } from './SectionTitle.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { useRenderLocation } from '../useRenderLocation';
import { MainComponents } from '../../types';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface SectionTitleProps {
  title: string;
  dataHook?: string;
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  className,
  dataHook = 'section-title',
}) => {
  const location = useRenderLocation();
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();
  const htmlTag =
    location === MainComponents.BODY
      ? settings.get(settingsParams.bodySectionTitleHtmlTag)
      : settings.get(settingsParams.sidebarTitleHtmlTag);

  return (
    <Text
      data-hook={dataHook}
      className={st(classes.title, { location, isMobile, isRTL }, className)}
      tagName={htmlTag}
    >
      {title}
    </Text>
  );
};

export default SectionTitle;
