import React from 'react';
import { st, classes } from './TimezoneIndication.st.css';
import SectionText from '../SectionText/SectionText';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { TimezoneType } from '@wix/bookings-uou-types';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import HiddenDropdown from '../HiddenDropdown/HiddenDropdown';
import { useRenderLocation } from '../useRenderLocation';
import { TimezoneSelection } from '@wix/bookings-viewer-ui';
import { SchedulingTimezoneViewModel } from '../../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';

export type TimezoneIndicationProps = {
  changeTimezoneCallback: (timezoneType: TimezoneType) => void;
  timezoneViewModel: SchedulingTimezoneViewModel;
};

const TimezoneIndicationComponent = ({
  changeTimezoneCallback,
  timezoneViewModel,
}: TimezoneIndicationProps) => {
  const { t } = useTranslation();
  const location = useRenderLocation();
  const { isMobile, isRTL } = useEnvironment();
  const timezoneLabel = t('app.scheduling.timezone-label');
  const { isBookingCalendarInstalled, timezoneLocale } = timezoneViewModel;

  if (isBookingCalendarInstalled) {
    const timezoneTypeMapping =
      timezoneViewModel.timezoneDropdownOptions?.reduce((acc, option) => {
        acc[option.value] = option.id;
        return acc;
      }, {}) || {};
    return (
      <div className={st(classes.timezoneDropDownPicker, { location })}>
        <TimezoneSelection
          locale={timezoneLocale}
          onTimezoneSelected={(value) =>
            changeTimezoneCallback(timezoneTypeMapping[value])
          }
          timezoneOptions={
            timezoneViewModel.timezoneDropdownOptions?.map(
              (option) => option.value,
            ) || []
          }
          timezoneLabel={timezoneLabel}
          isMobile={isMobile}
          selectedTimezone={timezoneViewModel.viewTimezone!}
        />
      </div>
    );
  } else {
    const changeTimezoneButton = () =>
      isMobile ? (
        <HiddenDropdown
          actionText={t('app.scheduling.timezone.change.mobile')}
          onDropdownChange={changeTimezoneCallback}
          selectedOption={timezoneViewModel.viewTimezoneType}
          dropDownOptions={timezoneViewModel.timezoneDropdownOptions!}
        />
      ) : (
        <TextButton
          className={st(classes.timezoneAction, { location })}
          data-hook={'desktop-timezone-toggle'}
          onClick={() =>
            changeTimezoneCallback(
              timezoneViewModel.viewTimezoneType === TimezoneType.BUSINESS
                ? TimezoneType.CLIENT
                : TimezoneType.BUSINESS,
            )
          }
        >
          {timezoneViewModel.viewTimezoneType === TimezoneType.BUSINESS
            ? t('app.scheduling.timezone.change-to-local')
            : t('app.scheduling.timezone.change-to-business')}
        </TextButton>
      );

    return (
      <div
        className={st(classes.timezoneIndicationContainer, { isRTL, isMobile })}
        data-hook={'timezone-indicator'}
      >
        <SectionText
          dataHook={'timezone-label'}
          className={classes.timezoneLabel}
        >
          {timezoneLabel} {timezoneViewModel.viewTimezone}{' '}
        </SectionText>
        {timezoneViewModel.canChangeTimezone && (
          <SectionText className={classes.timezoneActionWrapper}>
            {changeTimezoneButton()}
          </SectionText>
        )}
      </div>
    );
  }
};

export default TimezoneIndicationComponent;
