import React from 'react';
import { st, classes } from './HiddenDropdown.st.css';
import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import SectionText from '../SectionText/SectionText';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface HiddenDropdown {
  onDropdownChange: Function;
  actionText: string;
  dropDownOptions: DropdownOptionProps[];
  selectedOption?: string;
}

const HiddenDropdown: React.FunctionComponent<HiddenDropdown> = ({
  onDropdownChange,
  actionText,
  selectedOption,
  dropDownOptions,
}) => {
  const { isMobile } = useEnvironment();
  return (
    <SectionText
      className={st(classes.label, { isMobile })}
      dataHook={'hidden-dropdown-label'}
      tagName={'label'}
    >
      {actionText}
      <Dropdown
        data-hook={'hidden-dropdown'}
        className={classes.dropdown}
        options={dropDownOptions.map((value) => ({
          ...value,
          isSelectable: true,
        }))}
        initialSelectedId={selectedOption}
        onChange={({ id }) => onDropdownChange(id)}
        mobileNativeSelect
        upgrade
      />
    </SectionText>
  );
};

export default HiddenDropdown;
